<script setup lang="ts">
import { defineEmits, defineProps } from "vue";

const props = defineProps<{
    historyMap: string[]
}>();

const emits = defineEmits<{
    (e: "goBack"): Promise<void>,
}>();

const goBack = () => {
    emits("goBack");
}

</script>

<template>
    <nav class = "navigation">
         <button v-if="historyMap.length > 0" @click = "goBack" class = "title__btn">
            <i class="bi bi-arrow-left title__back" ></i>
        </button>
        <div class = navigation__item v-for="(item, index) in historyMap">

            <i v-if = "index !== 0" class = "navigation__item__arrow"> > </i>    
            <button class = "navigation__item__btn">
                <span 
                    class = 'navigation__item__btn__text'> 
                        {{ item }} 
                </span>
            </button>
        </div>
    </nav>

</template>

<style>
.navigation__border {
    border-bottom: 1px solid var(--bs-gray-300);
}

.title__btn {
    background: none;
    border: none;
}


.title__back {
    font-size: 1.5rem;
    margin-right: 10px;
}

</style>